import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";
import { MdPlayArrow } from "react-icons/md";

const CtaBanner = ({
  headline,
  btnTxt,
  btnLink,
  btn2Txt,
  btn2Link,
  bgColor,
  desc,
}) => {
  const ctaLink = btnLink || "/contact-us";

  return (
    <section className={`bg-${bgColor} py-5`}>
      <Container>
        <Row className="align-items-lg-center justify-content-center">
          <Col xl={10} className="mb-4 mb-lg-0 text-center">
            <h2 className="my-auto fs-2 fs-lg-1 text-white">{headline}</h2>
            {desc && <p className="text-white mt-5">{desc}</p>}
          </Col>
          <Col xs={12} md={btn2Link ? 12 : 5} xl={btn2Link ? 7 : 3} className="mx-auto text-center d-flex flex-column flex-md-row">
            <Button
              as={Link}
              variant={`primary`}
              size="lg"
              className=" cta-btn w-100  text-white mt-5 me-3"
              to={ctaLink}
              id="cta-banner-button"
            >
              {btnTxt}
            </Button>
            {btn2Link && (
              <Button
                as={Link}
                variant={`secondary`}
                size="lg"
                className=" cta-btn w-100  justify-content-center text-white mt-5 d-flex align-items-center"
                to={btn2Link}
                id="cta-banner-button"
              >
                {btn2Txt} <MdPlayArrow className="ms-1" /> 
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CtaBanner;
